<!--
 * @Autor: 符应飞
 * @Date: 2021-10-30 11:29:54
 * @LastEditors: 符应飞
 * @Description: 目录首页 
 * @LastEditTime: 2021-10-30 11:29:54
-->
<template>
  <div>
      <!-- 标题 -->
      <van-nav-bar
        title="人脸识别"
        :fixed="true"
      />
      <!-- 图片 -->
      <div class="img-container">
          <div class="img-container-image">
            <img src="@/assets/images/bg-cover.png" alt="">
          </div>
          
          <!-- 提示 -->
          <div class="tip-container">
            <p>温馨提示：识别过程尽量不要在光线暗的地方，脸部不能有遮挡等等</p>
          </div>

           <!-- 按钮 -->
           <div class="btn-group" @click="toVerify">
             <button>人脸录入</button>
           </div>

          <!-- 开始认证 -->
          <div class="btn-group" style="margin: 10px auto 30px;" @click="clickTab">
             <button>人脸登录</button>
           </div>
           
      </div>

      <!-- 版本号 -->
      <div class="version-view">
        <p>Copyright 1.0.1</p>
      </div>
  </div>
</template>
<script>
import { Toast } from 'mint-ui';
import {reqExistFace} from "@/api/verify"
import {mapState} from 'vuex'
import { removeToken } from '@/utils/auth'
export default {
  data() {
    return {

    }
  },
  computed:{
    ...mapState(['token','faceInfo','userId'])
  },
  mounted() {
    
    this.$nextTick(async () => {
      this.isExitFace()
      // 判断ios还是安卓
      this.checkDevice()
    })
  },
  methods: {
    isExitFace() {

      reqExistFace({
        token:this.token
      }).then(res => {

        if(res.code ==0 ) {
          let faceInfo = res.exist==true? res.faceImg : '';
          this.$store.commit('SET_FACEINFO',faceInfo);
          window.localStorage.setItem('faceInfo',faceInfo)
        } else {
          removeToken();
          this.$router.push('/login')
        }
      })
    },
    toVerify() {
      
      if(this.faceInfo) {
         this.$router.push('/update')    
      }else {
        this.$router.push('/verify')
      }
      
    },
    clickTab() {
      this.$router.push('/face')
    },
    // 判断ios还是安卓
    checkDevice() {
      let ua = navigator.userAgent.toLowerCase();
      let device = '';
       console.log('ua',ua)
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            //ios
           device = 'ios'
        } else if(/(Android)/i.test(navigator.userAgent)) {
            //android
           device = 'android'
        }  

       console.log('device',device)
      this.$store.commit('SET_DEVICE',device)
    }
  },
}
</script>
<style lang="less">
.version-view{
  position: fixed;
  bottom: 20px;
  left: 0;
  width: 100%;
  p{
    width: 100%;
    text-align: center;
    color: #ccc;
    font-size: 14px;
  }
}
  .img-container{
    margin-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img-container-image{
      img{
        width: 5.333333rem;
        height: auto;
      }
    }
    .tip-container{
      width: 100%;
      
      p{
        width: 80%;
        margin: 30px auto;
        text-align: center;
       font-size: 14px;
        color: #888;
      }
    }
    .btn-group{
      width: 80%;
     margin: 15px auto 15px;
      background-color: #0077ff;
      button{
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #fff;
        background-color:#0077ff;
        border: none;
        padding: 0;
      }
    }
  }
</style>